import React from "react";
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import { Card, Form } from "antd";
import {
  useCategoriesQuery,
  useDeleteTransactionMutation,
  useTransactionQuery,
  useUdateTransactionMutation,
} from "~shared/api/queries";
import { FormLayout } from "~shared/ui";
import { TransactionFields, TransactionForm } from "./TransactionsForm";
import { useUserContext } from "~shared/contexts/UserContext";
import useNotification from "antd/es/notification/useNotification";
import { toFixed2String } from "~shared/helpers";

export const TransactionEditPage = () => {
  const { user } = useUserContext();
  const navigate = useNavigate();
  const [form] = Form.useForm<TransactionFields>();

  const { id } = useParams();
  const numericId = parseInt(id!);
  const [api, contextHolder] = useNotification();

  const { data: categoriesData } = useCategoriesQuery({ page: 1, limit: 20 });
  const { data, isLoading } = useTransactionQuery(numericId);
  const { mutateAsync, isPending } = useUdateTransactionMutation(numericId);

  const { mutateAsync: deleteAsync, isPending: isDeleting } =
    useDeleteTransactionMutation(numericId, {
      onSuccess: () => {
        navigate("/app");
      },
    });

  React.useEffect(() => {
    if (data) {
      const localDate = dayjs.utc(data.date).local();
      form.setFieldsValue({
        amount: parseFloat(parseFloat(data.amount).toFixed(2)),
        categoryId: data.categoryId,
        date: localDate,
        time: localDate,
        name: data.name,
      });
    }
  }, [data, form]);

  const options = React.useMemo(() => {
    const categories =
      categoriesData?.pages
        .flat()
        .map(({ data }) => data)
        .flat() ?? [];

    return categories.map((category) => ({
      label: category.name,
      value: category.id,
    }));
  }, [categoriesData]);

  const handleOnFinish = async (values: TransactionFields) => {
    const { date, time, amount, ...rest } = values;

    const parsedDate = date.format("YYYY-MM-DD");
    const parsedTime = time.format("HH:mm:ss");
    const dateTime = dayjs(`${parsedDate}T${parsedTime}`);

    const payload = {
      ...rest,
      date: dateTime.utc().format(),
      userId: user!.id,
      amount: toFixed2String(amount),
      id: numericId,
    };

    await mutateAsync(payload);

    api.success({ message: "Transaction updated!" });
  };

  const handleDelete = async () => {
    await deleteAsync();
  };

  return (
    <FormLayout backUrl="/app" title="Create Transaction">
      <Card>
        {contextHolder}
        <TransactionForm
          primaryButtonLabel="Update"
          form={form}
          categories={options}
          isDisabled={isLoading || isPending || isDeleting}
          onFinish={handleOnFinish}
          secondaryButton={{
            color: "danger",
            variant: "filled",
            label: "Delete",
            onClick: handleDelete,
          }}
        />
      </Card>
    </FormLayout>
  );
};
