import React from "react";
import styled, { css } from "styled-components";

import { Link, useLocation } from "react-router-dom";

type TabLink = {
  Icon: React.FC;
  to: string;
};

type TabNavigationProps = {
  links: TabLink[];
  isIOS: boolean;
};

export const TabNavigation: React.FC<TabNavigationProps> = ({
  links,
  isIOS,
}) => {
  const { pathname } = useLocation();

  return (
    <TabBar $isIOS={isIOS}>
      {links.map(({ to, Icon }) => {
        return (
          <Link key={to} to={to}>
            <LinkContainer $active={pathname === to}>
              <Icon />
            </LinkContainer>
          </Link>
        );
      })}
    </TabBar>
  );
};

const TabBar = styled.div<{ $isIOS: boolean }>`
  min-height: 64px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #001529;
  padding: ${({ theme }) => `${theme.antd.paddingSM}px 0`};

  ${({ $isIOS, theme }) =>
    $isIOS &&
    css`
      padding-bottom: ${({ theme }) => `${theme.antd.paddingLG}px`};
    `}
`;

const LinkContainer = styled.div<{ $active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: ${({ theme }) => theme.antd.marginXS}px;
  color: ${({ theme, $active }) =>
    $active ? theme.antd.colorPrimary : theme.antd.colorWhite};
  font-size: 22px;
  padding: ${({ theme }) => `${theme.antd.paddingSM}px`};
`;
