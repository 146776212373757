import {
  useMutation,
  useQueryClient,
  UseMutationOptions,
} from "@tanstack/react-query";
import { MUTATIONS, QUERIES } from "./queries";

import { deleteCategory } from "../endpoints/Categories";

export const useDeleteCategoryMutation = (
  id: number,
  options?: Omit<UseMutationOptions, "mutationKey" | "mutationFn">
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATIONS.deleteCategory],
    mutationFn: () => deleteCategory(id),
    ...options,
    onSuccess: async (data, variables, context) => {
      await queryClient.resetQueries({
        queryKey: [QUERIES.categories, [QUERIES.categories, id]],
      });

      options?.onSuccess?.(data, variables, context);
    },
  });
};
