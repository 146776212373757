import { fetchHelper } from "~shared/helpers/fetchHelper";

export type CreateTransactionData = {
  amount: string;
  categoryId: number;
  date: string;
  name: string;
  userId: number;
};

export const createTransaction = (data: CreateTransactionData) => {
  return fetchHelper({ url: "/ledger", method: "POST", body: data });
};
