import { Spin } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

export const LogoutPage: React.FC = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    localStorage.removeItem("access_token");
    navigate("/login");
  }, [navigate]);

  return <Spin />;
};
