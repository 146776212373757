import React from "react";
import { Button, Card, Form, Input } from "antd";
import { FormLayout } from "~shared/ui";
import { useNavigate, useParams } from "react-router-dom";
import { useUserContext } from "~shared/contexts/UserContext";
import {
  useCategoryUpdateMutation,
  useDeleteCategoryMutation,
} from "~shared/api/queries";
import { useCategoryQuery } from "~shared/api/queries/useCategoryQuery";
import useNotification from "antd/es/notification/useNotification";

type CreateCategoryFields = {
  name: string;
};

export const UpdateCategoryPage: React.FC = () => {
  const { user } = useUserContext();
  const [form] = Form.useForm<CreateCategoryFields>();
  const navigate = useNavigate();
  const [api, contextHolder] = useNotification();

  const { id } = useParams();

  const numericId = parseInt(id!);

  const { data: category, isLoading } = useCategoryQuery(numericId);
  const { mutateAsync, isPending } = useCategoryUpdateMutation(numericId);
  const { mutateAsync: deleteAsync, isPending: isDeleting } =
    useDeleteCategoryMutation(numericId);

  const onFinish = async (data: CreateCategoryFields) => {
    await mutateAsync({ userId: user!.id, ...data });
    api.success({ message: "Transaction updated!" });
  };

  const handleDelete = async () => {
    await deleteAsync();
    navigate("/app/categories");
  };

  React.useEffect(() => {
    if (category) {
      form.setFieldsValue({ name: category.name });
    }
  }, [category, form]);

  return (
    <FormLayout title="Update Category" backUrl="/app/categories">
      {contextHolder}
      <Card>
        <Form
          form={form}
          size="large"
          layout="horizontal"
          onFinish={onFinish}
          disabled={isPending || isLoading || isDeleting}
        >
          <Form.Item
            label="Category Name"
            name="name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Groceries" />
          </Form.Item>
          <Form.Item style={{ width: "100%", marginBottom: 12 }}>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Update
            </Button>
          </Form.Item>
          <Form.Item style={{ width: "100%" }}>
            <Button
              color="danger"
              variant="filled"
              onClick={handleDelete}
              block
            >
              Delete
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </FormLayout>
  );
};
