import { fetchHelper } from "~shared/helpers/fetchHelper";
import { LoginData } from "./types";

type LoginResponse = {
  accessToken: string;
};

export const login = async (data: LoginData): Promise<LoginResponse> => {
  return await fetchHelper({
    url: "/auth/login",
    method: "POST",
    body: data,
  });
};
