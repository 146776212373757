import { fetchHelper } from "~shared/helpers/fetchHelper";

export type UpdateCategoryData = {
  name: string;
  userId: number;
};

export const updateCategory = (id: number, data: UpdateCategoryData) => {
  return fetchHelper({
    url: `/categories/${id}`,
    method: "PATCH",
    body: data,
  });
};
