import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "./queries";
import { fetchTransaction } from "../endpoints/Transactions";

export const useTransactionQuery = (id: number) => {
  return useQuery({
    queryKey: [QUERIES.transaction, id],
    queryFn: () => fetchTransaction(id),
  });
};
