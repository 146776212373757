import { fetchHelper } from "~shared/helpers/fetchHelper";

export type User = {
  id: number;
  email: string;
  userName: string;
};

export const fetchMe = async (): Promise<User> => {
  return await fetchHelper<User>({ url: "/users/me" });
};
