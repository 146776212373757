import { fetchHelper } from "~shared/helpers/fetchHelper";

export type CreateCategoryData = {
  name: string;
  userId: number;
};

export const createCategory = (data: CreateCategoryData) => {
  return fetchHelper({ url: "/categories", method: "POST", body: data });
};
