import React from "react";
import { UserContext } from "./UserContext";
import * as Users from "~shared/api/endpoints/Users";

export const UserContextProvier: React.FC<React.PropsWithChildren> = (
  props
) => {
  const [user, setUser] = React.useState<Users.User | null>(null);

  const { children } = props;
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};
