import { Button, Empty, Flex } from "antd";
import React from "react";
import styled from "styled-components";

type EmptyDataProps = {
  buttonLabel: string;
  description?: string;
  onClick: () => void;
};

export const EmptyData: React.FC<EmptyDataProps> = (props) => {
  const { buttonLabel, description, onClick } = props;

  return (
    <Container>
      <Flex vertical gap={20} align="center">
        <Empty description={description} />
        <div>
          <Button type="primary" onClick={onClick}>
            {buttonLabel}
          </Button>
        </div>
      </Flex>
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  transform: translateY(-10%);
  padding: 0 10%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
