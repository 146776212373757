import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "./queries";
import { fetchMe } from "../endpoints/Users";

export const useMeQuery = () => {
  return useQuery({
    queryKey: [QUERIES.user],
    queryFn: fetchMe,
    retry: false,
  });
};
