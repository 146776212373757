import * as React from "react";

export const setupContext = <T>(params: { ctx?: T; displayName: string }) => {
  const Context = React.createContext<T | null>(params.ctx ?? null);

  Context.displayName = params.displayName;

  const useConsumer = () => React.useContext(Context);

  const useSafeConsumer = () => {
    const ctx = useConsumer();

    if (ctx === null) {
      throw new Error(`Requires provider for ${Context.displayName}`);
    }

    return ctx;
  };

  return {
    Context,
    useConsumer,
    useSafeConsumer,
  };
};
