import { Flex, Typography } from "antd";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";

type DateProps = {
  date: string;
};

export const Date: React.FC<DateProps> = (props) => {
  const format = dayjs(props.date);

  const day = format.format("DD");
  const month = format.format("MMM");

  return (
    <DateContainer vertical align="center" justify="center">
      <Day strong>{day}</Day>
      <Month type="secondary">{month}</Month>
    </DateContainer>
  );
};

const DateContainer = styled(Flex)`
  padding: ${({ theme }) => `0 ${theme.antd.paddingXL}px 0 0`} !important;
`;

const Month = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.antd.sizeXS}px;
`;

const Day = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.antd.sizeSM}px;
`;
