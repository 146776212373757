import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MUTATIONS, QUERIES } from "./queries";
import { createCategory, CreateCategoryData } from "../endpoints/Categories";

export const useCategoryMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: [MUTATIONS.transaction],
    mutationFn: (data: CreateCategoryData) => createCategory(data),

    onSuccess: async () => {
      await queryClient.resetQueries({ queryKey: [QUERIES.categories] });
    },
  });
};
