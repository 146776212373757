import React from "react";
import { Button, Card, Form, Input } from "antd";
import { FormLayout } from "~shared/ui";
import { useCategoryMutation } from "~shared/api/queries";
import { useNavigate } from "react-router-dom";
import { useUserContext } from "~shared/contexts/UserContext";

type CreateCategoryFields = {
  name: string;
};

export const CreateCategoryPage: React.FC = () => {
  const { user } = useUserContext();
  const { mutateAsync, isPending } = useCategoryMutation();
  const navigate = useNavigate();

  const onFinish = async (data: CreateCategoryFields) => {
    await mutateAsync({ userId: user!.id, ...data });
    navigate("/app/categories");
  };

  return (
    <FormLayout title="Create Category" backUrl="/app/categories">
      <Card>
        <Form
          size="large"
          layout="horizontal"
          onFinish={onFinish}
          disabled={isPending}
        >
          <Form.Item
            label="Category Name"
            name="name"
            rules={[{ required: true }]}
          >
            <Input placeholder="Groceries" />
          </Form.Item>
          <Form.Item style={{ width: "100%" }}>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
              Create
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </FormLayout>
  );
};
