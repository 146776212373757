import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MUTATIONS, QUERIES } from "./queries";
import { updateCategory, UpdateCategoryData } from "../endpoints/Categories";

export const useCategoryUpdateMutation = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: [MUTATIONS.transaction],
    mutationFn: (data: UpdateCategoryData) => updateCategory(id, data),
    onSuccess: async () => {
      await queryClient.resetQueries({
        queryKey: [QUERIES.categories],
      });
    },
  });
};
