type FetchHelperOptions = {
  url: string;
  method?: "GET" | "POST" | "DELETE" | "PATCH";
  isProtected?: boolean;
  body?: Record<string, any>;
};

export const fetchHelper = <T>(params: FetchHelperOptions) => {
  const { body, url, method = "GET", isProtected = true } = params;

  let headers = new Headers({
    Accept: "application/json",
    "Content-Type": "application/json",
  });

  if (isProtected) {
    const token = localStorage.getItem("access_token");
    headers.set("Authorization", `Bearer ${token}`);
  }

  const apiBase = process.env.REACT_APP_BASE_API;

  return fetch(`${apiBase}${url}`, {
    method: method,
    headers,
    body: JSON.stringify(body),
  }).then((response) => {
    if (response.ok) {
      return response.json() as T;
    }

    throw new Error(String(response.status));
  });
};
