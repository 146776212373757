import { PaginatedResponse } from "~shared/api/types";
import { fetchHelper } from "~shared/helpers/fetchHelper";
import { Category } from "./types";

type FetchCategoriesParams = {
  page: number;
};

export const fetchCategories = (params: FetchCategoriesParams) => {
  const { page = 1 } = params;
  return fetchHelper<PaginatedResponse<Category>>({
    url: `/categories?page=${page}`,
  });
};
