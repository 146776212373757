import {
  Button,
  ButtonProps,
  DatePicker,
  Flex,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Select,
  TimePicker,
} from "antd";
import { DefaultOptionType } from "antd/es/select";
import { Dayjs } from "dayjs";
import React from "react";

export type TransactionFields = {
  name: string;
  amount: number;
  date: Dayjs;
  time: Dayjs;
  categoryId: number;
};

type TransactionFormProps = {
  isDisabled: boolean;
  onFinish: (values: TransactionFields) => void | Promise<void>;
  form?: FormInstance<TransactionFields>;
  primaryButtonLabel: string;
  categories: DefaultOptionType[];
  secondaryButton?: {
    label: string;
    color: ButtonProps["color"];
    variant: ButtonProps["variant"];
    onClick: () => void;
  };
};

export const TransactionForm: React.FC<TransactionFormProps> = (props) => {
  const {
    categories,
    form,
    isDisabled,
    onFinish,
    primaryButtonLabel,
    secondaryButton,
  } = props;

  return (
    <Form
      form={form}
      disabled={isDisabled}
      onFinish={onFinish}
      layout="vertical"
      size="large"
    >
      <Form.Item<TransactionFields> label="Title" name="name">
        <Input placeholder="Groceries" />
      </Form.Item>
      <Form.Item<TransactionFields>
        label="Amount"
        name="amount"
        style={{ display: "inline-block" }}
      >
        <InputNumber type="number" placeholder="100.23" />
      </Form.Item>
      <Flex justify="flex-start" gap={48}>
        <Form.Item<TransactionFields> label="Date" name="date">
          <DatePicker inputReadOnly placeholder="22/10/2024" />
        </Form.Item>
        <Form.Item<TransactionFields> label="Time" name="time">
          <TimePicker inputReadOnly format="HH:mm" placeholder="18:30" />
        </Form.Item>
      </Flex>
      <Form.Item<TransactionFields> label="Category" name="categoryId">
        <Select options={categories} />
      </Form.Item>

      <Form.Item style={{ width: "100%", marginBottom: 12 }}>
        <Button htmlType="submit" type="primary" style={{ width: "100%" }}>
          {primaryButtonLabel}
        </Button>
      </Form.Item>

      {!!secondaryButton && (
        <Form.Item style={{ width: "100%" }}>
          <Button
            color={secondaryButton.color}
            variant={secondaryButton.variant}
            onClick={secondaryButton.onClick}
            block
          >
            {secondaryButton.label}
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};
