import React from "react";
import { Button, Card, Form, Input, Layout, notification } from "antd";
import styled from "styled-components";
import { useMutation } from "@tanstack/react-query";
import { createUser } from "~shared/api/endpoints/Auth";
import { Header } from "~shared/ui/Layout/Header";
import { BackIcon } from "~shared/ui";
import { Link } from "react-router-dom";

type RegisterFields = {
  email: string;
  username: string;
  password: string;
  password2: string;
};

export const RegisterPage: React.FC = () => {
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();

  const { mutateAsync, isPending } = useMutation({
    mutationFn: createUser,
    mutationKey: ["CREATE-USER-MUTATION"],
  });

  const onFinish = async (values: RegisterFields) => {
    try {
      await mutateAsync({
        email: values.email,
        password: values.password,
        username: values.username,
      });

      api.success({
        message: "User created!",
      });
    } catch (e: unknown) {
      const error = e as Error;

      if (error.message === "409") {
        api.error({
          message: "Username or email already taken",
        });
      }
    }
  };

  return (
    <StyledLayout>
      <Header
        transparent
        Start={
          <Link to="/login">
            <BackIcon $black />
          </Link>
        }
      />
      <Container>
        {contextHolder}
        <StyledCard size="small">
          <Form
            disabled={isPending}
            form={form}
            onFinish={onFinish}
            layout="vertical"
            colon={true}
            size="large"
          >
            <Form.Item
              label="Username"
              name="username"
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item label="Email" name="email" rules={[{ required: true }]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true }]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label="Repeat Password"
              name="password2"
              dependencies={["password"]}
              rules={[
                { required: true },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error("Passwords do not match!"));
                  },
                }),
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button
                style={{ width: "100%" }}
                type="primary"
                htmlType="submit"
              >
                Create Account
              </Button>
            </Form.Item>
          </Form>
        </StyledCard>
      </Container>
    </StyledLayout>
  );
};

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const StyledCard = styled(Card)`
  width: 70vw;
`;

const StyledLayout = styled(Layout)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.antd.colorWhite};
`;
