import React from "react";
import { Flex, List, Typography } from "antd";
import styled from "styled-components";
import { Category } from "~shared/api/endpoints/Categories";

type CategoryListItemProps = {
  category: Category;
};

export const CategoryListItem: React.FC<CategoryListItemProps> = (props) => {
  const {
    category: { name },
  } = props;

  return (
    <StyledListItem>
      <Flex align="center" justify="space-between" flex={1}>
        <Title>{name}</Title>
      </Flex>
    </StyledListItem>
  );
};

const StyledListItem = styled(List.Item)`
  padding: ${({ theme }) =>
    `${theme.antd.paddingMD}px ${theme.antd.paddingXL}px`} !important;
  border-bottom: 1px solid ${({ theme }) => theme.antd.colorBorder} !important;
`;

const Title = styled(Typography.Text)`
  font-size: ${({ theme }) => theme.antd.fontSizeLG}px;
  text-transform: capitalize;
`;
