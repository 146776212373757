import React from "react";
import { Spin } from "antd";
import { Outlet, useNavigate } from "react-router-dom";
import { useMeQuery } from "~shared/api/queries";
import { useUserContext } from "~shared/contexts/UserContext";
import { Center } from "~shared/ui";

export const PrivateRoute = () => {
  const navigate = useNavigate();
  const token = localStorage.getItem("access_token");
  const { setUser } = useUserContext();

  const { data, isLoading, isError } = useMeQuery();

  React.useEffect(() => {
    setUser(data ?? null);
  }, [setUser, data]);

  if (!token) {
    navigate("/");
    return null;
  }

  if (isError) {
    localStorage.removeItem("access_token");
    navigate("/");

    return null;
  }

  if (isLoading) {
    return (
      <Center>
        <Spin />
      </Center>
    );
  }

  return <Outlet />;
};
