import React from "react";
import { theme } from "antd";
import { ThemeProvider } from "styled-components";
import { Router } from "~core/Router";
import { Reset } from "~styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { UserContextProvier } from "~shared/contexts/UserContext";

export const Application: React.FC = () => {
  const { token } = theme.useToken();
  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={{ antd: token }}>
          <UserContextProvier>
            <Reset />
            <Router />
          </UserContextProvier>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
};
