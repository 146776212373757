import * as Users from "~shared/api/endpoints/Users";
import { setupContext } from "~shared/helpers/createContext";

type UserContextType = {
  user: Users.User | null;
  setUser: (user: Users.User | null) => void;
};

export const { Context: UserContext, useSafeConsumer: useUserContext } =
  setupContext<UserContextType>({ displayName: "UserContext" });
