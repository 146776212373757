import { Flex } from "antd";
import styled from "styled-components";

export const Center = styled(Flex).attrs({
  align: "center",
  justify: "center",
})`
  width: 100%;
  height: 100%;
`;
