import { useInfiniteQuery } from "@tanstack/react-query";
import { QUERIES } from "./queries";
import { fetchTransactions } from "../endpoints/Transactions";

type TransactionsQueryparams = {
  page: number;
  limit: number;
};

export const useTransactionsQuery = (params: TransactionsQueryparams) => {
  return useInfiniteQuery({
    queryKey: [QUERIES.transactions],
    queryFn: ({ pageParam }) => fetchTransactions({ page: pageParam }),
    initialPageParam: params.page ?? 1,
    getNextPageParam: ({ meta }) => {
      const { page, size, total } = meta;
      const totalpages = Math.ceil(total / size);

      return page === totalpages ? null : page + 1;
    },
    retry: false,
  });
};
