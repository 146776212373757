import { useQuery } from "@tanstack/react-query";
import { QUERIES } from "./queries";
import { fetchCategory } from "../endpoints/Categories";

export const useCategoryQuery = (id: number) => {
  return useQuery({
    queryKey: [QUERIES.categories, id],
    queryFn: () => fetchCategory(id),
  });
};
